/*!
 * Bootstrap v4.6.0 (https://getbootstrap.com/)
 * Branded for Gelsenwasser / Termintool
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

@import "styles/gw-colors";
@import "styles/gw-fonts";

$font-weight-bold: 500;
$font-weight-bolder: 700;

$enable-rounded: false;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1400px,
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1380px,
);

$jumbotron-padding: 2rem;
$jumbotron-bg: #fff;

$breadcrumb-padding-y: 0.75rem;
$breadcrumb-padding-x: 0rem;

$font-family-sans-serif: "Roboto";

@import "styles/bs-functions";

$breadcrumb-bg: theme-color("gw-light");
$headings-color: theme-color("primary");

@import "styles/bs-components";

// damit zwischen einzelnen zeilen ein abstand ist
.row {
  margin-top: $spacer;
}

// damit zwischen containern ein abstand ist
.container {
  margin-top: ($spacer * 0.5);
}

.card-title {
  font-family: "Roboto Condensed";
  font-size: $font-size-base * 1.5;
}
.card-header {
  font-size: $font-size-base * 1.5;
}

//TODO: pixelwerte in rem oder variablen ändern
.display-4 {
  padding-bottom: 30px;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: 10%;
    height: 1px;
    bottom: 0;
    left: 0;
    border-bottom: 2px solid theme-color("primary");
    margin-bottom: 12px;
  }
}

.bg-gradient {
  background: transparent linear-gradient(-225deg, theme-color("primary") 35%, theme-color("green") 100%) 0% 0%
    no-repeat padding-box;
}

.outermain {
  overflow-x: hidden;
}

.footer {
  height: 5em;
  position: static;
}

.spin {
  animation: spin 2s infinite linear;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

.loginButton > button {
  border: 0;
}

// Links werden immer in BOLD angezeigt
main {
  a {
    font-weight: bold;
  }
}
// außer es sind links ohne HREF, dann werden diese normal angezeigt
main {
  a:not([href]):not([class]) {
    font-weight: normal;
  }
}
