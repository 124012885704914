@import "gw-colors.scss";
@import "styles/bs-functions";
// $theme-colors: (
//   "primary": #00467d,
//   "blue": #00467d,
//   "secondary": #dcdbd9,
//   "green": #00c713,
//   "indigo": #003763,
//   "cyan": #37afb9,
//   "purple": #500a78,
//   "dark": #29343a,
//   "gw-yellow": #d6e000,
//   "gw-lightblue": #9bd7dc,
//   "gw-light": #f9f9f9,
//   "white": #fff,
//   "danger":  #dc3545,
//   "pink":#d63384
// );
.react-calendar {
  width: 100%;
  max-width: 100%;
  background: theme-color("white");
  border: 1px solid theme-color("secondary");
  font-family: "Roboto";
  line-height: 1.125em;

  &--doubleView {
    width: 700px;

    .react-calendar__viewContainer {
      display: flex;
      margin: -0.5em;

      > * {
        width: 50%;
        margin: 0.5em;
      }
    }
  }

  &,
  & *,
  & *:before,
  & *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  button {
    margin: 0;
    border: 0;
    outline: none;
    // color: theme-color("primary");

    &:enabled {
      &:hover {
        cursor: pointer;
      }
    }
  }

  &__navigation {
    display: flex;
    height: 44px;
    margin-bottom: 1em;
    border-bottom: 1px solid theme-color("secondary");

    button {
      min-width: 44px;
      background: none;

      &:enabled {
        &:hover,
        &:focus {
          background-color: rgb(230, 230, 230);
        }
      }

      &[disabled] {
        background-color: rgb(240, 240, 240);
      }
    }
  }

  &__month-view {
    &__weekdays {
      text-align: center;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 0.75em;

      &__weekday {
        padding: 0.5em;
      }
      abbr {
        text-decoration: none;
      }
    }

    &__weekNumbers {
      .react-calendar__tile {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.75em;
        font-weight: bold;
        padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
      }
    }

    &__days {
      &__day {
        &--weekend {
          color: theme-color("danger");
        }

        &--neighboringMonth {
          color: rgb(117, 117, 117);
        }
      }
    }
  }

  &__year-view,
  &__decade-view,
  &__century-view {
    .react-calendar__tile {
      padding: 2em 0.5em;
    }
  }

  &__tile {
    max-width: 100%;
    text-align: center;
    padding: 0.75em 0.5em;
    background: none;

    &:disabled {
      background-color: theme-color("gw-light");
    }

    &:enabled {
      &:hover,
      &:focus {
        background-color: theme-color("secondary");
      }
    }

    &--now {
      background: theme-color("green");
      color: theme-color("white") !important;

      &:enabled {
        &:hover,
        &:focus {
          background: theme-color("primary");
        }
      }
    }

    &--hasActive {
      $bgcolor: lighten(rgb(0, 110, 220), 30%);
      background: theme-color("primary");

      &:enabled {
        &:hover,
        &:focus {
          background: theme-color("green");
        }
      }
    }

    &--active {
      // $bgcolor: rgb(0, 110, 220);
      background: theme-color("primary");
      color: theme-color("white") !important;

      &:enabled {
        &:hover,
        &:focus {
          background: theme-color("primary");
        }
      }
    }
  }

  &--selectRange {
    .react-calendar__tile {
      &--hover {
        background-color: rgb(230, 230, 230);
      }
    }
  }
}
