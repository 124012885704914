@import "../../styles/gw-colors";
@import "~bootstrap/scss/functions";

.home {
  background-size: cover;
  height: 100%;
  width: 100%;
  background: linear-gradient(123deg, theme-color("primary") 0%, theme-color("green") 100%);
  display: flex;
  align-items: center;
  justify-content: center;
}
