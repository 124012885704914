@font-face {
    font-family: "Roboto";
    src: url("../fonts/Roboto-Regular-webfont.eot");
    src: url("../fonts/Roboto-Regular-webfont.eot?#iefix") format("embedded-opentyp"),
      url("../fonts/Roboto-Regular-webfont.woff") format("woff"),
      url("../fonts/Roboto-Regular-webfont.ttf") format("truetype");
      // url("../fonts/Roboto-Regular-webfont.svg#robotoregular") format("svg");
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: "Roboto";
    src: url("../fonts/Roboto-Light-webfont.eot");
    src: url("../fonts/Roboto-Light-webfont.eot?#iefix") format("embedded-opentyp"),
      url("../fonts/Roboto-Light-webfont.woff") format("woff"), url("../fonts/Roboto-Light-webfont.ttf") format("truetype");
      // url("../fonts/Roboto-Light-webfont.svg#robotoregular") format("svg");
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: "Roboto Condensed";
    src: url("../fonts/RobotoCondensed-Regular-webfont.eot");
    src: url("../fonts/RobotoCondensed-Regular-webfont.eot?#iefix") format("embedded-opentyp"),
      url("../fonts/RobotoCondensed-Regular-webfont.woff") format("woff"),
      url("../fonts/RobotoCondensed-Regular-webfont.ttf") format("truetype");
      // url("../fonts/RobotoCondensed-Regular-webfont.svg#robotoregular") format("svg");
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: "Roboto Condensed";
    src: url("../fonts/RobotoCondensed-Light-webfont.eot");
    src: url("../fonts/RobotoCondensed-Light-webfont.eot?#iefix") format("embedded-opentyp"),
      url("../fonts/RobotoCondensed-Light-webfont.woff") format("woff"),
      url("../fonts/RobotoCondensed-Light-webfont.ttf") format("truetype");
      // url("../fonts/RobotoCondensed-Light-webfont.svg#robotoregular") format("svg");
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: "Roboto Condensed";
    src: url("../fonts/RobotoCondensed-Bold-webfont.eot");
    src: url("../fonts/RobotoCondensed-Bold-webfont.eot?#iefix") format("embedded-opentyp"),
      url("../fonts/RobotoCondensed-Bold-webfont.woff") format("woff"),
      url("../fonts/RobotoCondensed-Bold-webfont.ttf") format("truetype");
      // url("../fonts/RobotoCondensed-Bold-webfont.svg#robotoregular") format("svg");
    font-weight: 700;
    font-style: normal;
  }