@import "../../styles/gw-colors";

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

$secondary: theme-color("green");
$light-gray: theme-color("white");
$gray: #b3b3b3;
$pale-blue: theme-color("primary");
$primary: theme-color("dark");
$red: theme-color("danger");
$white: theme-color("white");
$border: 5px;
$mileStoneSize: 28px;

.Steps__Wrapper {
  display: block;
}
.Steps {
  position: relative;
  display: flex;
  padding: 0;
  list-style: none;
  width: inherit;

  @media (max-width: map-get($grid-breakpoints, "sm")) {
    flex-direction: column;
  }
}

.Step {
  position: relative;
  font-size: 12px;
  flex: 1;

  &::before {
    content: "";
    background-color: $gray;
    position: absolute;
    @media (min-width: map-get($grid-breakpoints, "sm")) {
      width: 100%;
      height: $border;
    }
    @media (max-width: map-get($grid-breakpoints, "sm")) {
      height: 100%;
      bottom: 0;
      width: $border;
      left: ($mileStoneSize/2 - $border) + $border/2;
    }
  }

  &__Milestone {
    width: $mileStoneSize;
    height: $mileStoneSize;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $light-gray;
    border: $border solid $gray;
    position: relative;
    position: relative;
    @media (min-width: map-get($grid-breakpoints, "sm")) {
      bottom: 10px;
    }
  }
}

.Step__Completed {
  &::before {
    background: $secondary;
  }
  .Step__Milestone {
    background: $secondary;
    color: $white;
    border-color: $secondary;
  }
}

.Step__Invalid {
  .Step__Milestone {
    color: $red;
    border: $border solid $red;
  }
  .Step__Label {
    font-weight: bolder;
  }
}

.Step__Current {
  .Step__Milestone {
    color: $pale-blue;
    border-color: $secondary;
  }
  .Step__Label {
    font-weight: bolder;
  }
}

.Step__Label {
  color: $gray;
  margin-right: $spacer * 0.5;
  @media (max-width: map-get($grid-breakpoints, "sm")) {
    padding-left: 2.5rem;
    top: -(($mileStoneSize - $border * 2) + $border/2);
    position: relative;
  }
}

.Step__Invalid .Step__Label,
.Step__Completed .Step__Label,
.Step__Current .Step__Label {
  color: $primary;
}

.Step__Last::before {
  content: "";
  display: none;
}
